function generateGUID() {
  function S4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  return S4() + S4()
}
function getDomain(name) {
  let protocol = 'http:'
  let origin = window.location.origin
  let hostname = window.location.hostname
  // get protocol
  if (window.location.protocol === 'https:') {
    protocol = 'https:'
  }
  // current hostname
  // if production, use the env variable
  if (name === 'ghost') {
    if (process.env.NODE_ENV === 'development') {
      return 'http://localhost:3003/session'
    } else {
      return `${origin}/session`
      // return process.env[name.toUpperCase() + '_DOMAIN']
    }
  } else if (name === 'tree') {
    if (process.env.NODE_ENV === 'development') {
      return 'http://localhost:3003/tree'
    } else {
      return `${origin}/tree`
    }
  } else if (name === 'socket') {
    if (process.env.NODE_ENV === 'development') {
      return 'http://localhost:3003'
    } else {
      return `${origin}`
    }
  } else if (name === 'backend') {
    if (process.env.NODE_ENV === 'development') {
      return 'http://localhost:3003'
    } else {
      return `${origin}`
    }
  } else {
    if (process.env.NODE_ENV === 'development') {
      return process.env[name.toUpperCase() + '_DOMAIN']
    } else {
      return process.env[name.toUpperCase() + '_DOMAIN']
    }
  }
}

export { generateGUID, getDomain }

import Hls from 'hls.js'
import React, { useEffect, useRef, useState } from 'react'

const OptionsMenu = ({ options, onOptionSelect }) => {
  return (
    <div className="animate-fade-right animate-once animate-duration-500 animate-delay-100 animate-ease-in-out absolute pl-8 pr-6 w-1/3 h-full overflow-auto">
      <div className="w-full py-12">
        {/* {JSON.stringify(options)} */}
        {options.map((option, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                console.log('Option Selected', option.value)
                onOptionSelect(option.value)
              }}
              className={`w-full p-2 rounded-full hover:bg-blue-400 flex flex-wrap items-center cursor-pointer border border-white border-solid mb-4 animate-fade-right animate-once animate-duration-300 animate-delay-${
                (options.length - index) * 100
              } animate-ease-in-out`}
            >
              <div
                className={`
                w-12 h-12 rounded-full flex items-center justify-center bg-blue-700 font-bold text-white animate-jump-in animate-once animate-duration-300 animate-delay-${
                  index * 200
                }
              `}
              >
                {option.value}
              </div>
              <div className="px-4">{option.title}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default function VideoPlayer(props) {
  const [hls, setHls] = useState(new Hls())
  const videoEl = useRef(null)
  const { data, className, manifest, isDebugMode, clipInfo, onOptionSelect } =
    props

  const [playerReady, setPlayerReady] = useState(false)

  const checkClipLoop = (clip, currentTime) => {
    if (clip) {
      if (clip.loop) {
        if (currentTime >= clip.end) {
          videoEl.current.currentTime = clip.start
        }
      }
    }
  }

  useEffect(() => {
    if (videoEl.current && playerReady) {
      const video = videoEl.current
      // set current time
      if (clipInfo && clipInfo.currentTime) {
        // pause
        video.pause()
        // set current time
        video.currentTime = clipInfo.clip.start
        // play
        video.play()
      }

      if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
        let paintCount = 0
        let startTime = 0.0
        const metadataInfo = document.getElementById('metadataInfo')

        const updateCanvas = (now, metadata) => {
          if (startTime === 0.0) {
            startTime = now
          }

          // ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

          const elapsed = (now - startTime) / 1000.0
          const fps = (++paintCount / elapsed).toFixed(3)
          metadataInfo.innerHTML = `video fps: ${fps} <br> currentTime: ${video.currentTime} <br> start: ${clipInfo.clip.start} <br> end: ${clipInfo.clip.end}`
          // metadataInfo.innerText = JSON.stringify(metadata, null, 2)

          checkClipLoop(clipInfo.clip, video.currentTime)

          // Re-register the callback to run on the next frame
          video.requestVideoFrameCallback(updateCanvas)
        }

        // Initial registration of the callback to run on the first frame
        video.requestVideoFrameCallback(updateCanvas)
      } else {
        // alert('Your browser does not support requestVideoFrameCallback().')
        // add current time listener
        videoEl.current.addEventListener('timeupdate', (e) => {
          checkClipLoop(clipInfo.clip, e.target.currentTime)
          console.log('timeupdate', e.target.currentTime)
        })
      }
    }
  }, [clipInfo, playerReady])

  useEffect(() => {
    if (videoEl.current && manifest) {
      hls.attachMedia(videoEl.current)
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(
          manifest?.media?.hls ||
            'https://devstreaming-cdn.apple.com/videos/streaming/examples/bipbop_4x3/bipbop_4x3_variant.m3u8?81756'
        )
      })
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        console.log('MANIFEST_PARSED', manifest)
        setPlayerReady(true)
      })
      // to-do FALLBACK mp4
    }

    return () => {
      if (hls) {
        hls.destroy()
      }
      setHls(null)
    }
  }, [data])

  return (
    <div className="w-full h-full bg-black flex flex-col overflow-hidden">
      {isDebugMode && <div id="metadataInfo" className="w-full"></div>}
      <div className="w-full flex-1 relative">
        <div id="overlay-base" className="absolute w-full h-full z-10">
          {clipInfo?.options && clipInfo.options.length > 0 && (
            <OptionsMenu
              onOptionSelect={onOptionSelect}
              options={clipInfo.options}
            />
          )}
        </div>

        <div className="absolute w-full h-full bg-blue-600 z-0">
          <video
            ref={videoEl}
            //   src={data.src}
            autoPlay
            muted
            controls
            className={className}
          />
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { useEffect, useState, useParams } from 'react'
import axios from 'axios'
import { getDomain } from '../utils'
import { computeFingerprint } from 'simple-fingerprint'

const SessionWrapper = ({ children, routeParams }) => {
  const [session, setSession] = useState(null)
  const [remaining, setRemaining] = useState(0)
  const [totalRetries, setTotalRetries] = useState(0)
  const [manifest, setManifest] = useState(null)

  let retryTimeout = null

  const getManifestAndSession = async () => {
    console.log('getting_session')
    const localSessionToken = localStorage.getItem('chaflow_session')
    if (localSessionToken) {
      // refresh session
      console.log('REFRESHING_TOKEN', localSessionToken)
      axios
        .post(`${getDomain('ghost')}/token`, {
          token: JSON.parse(localSessionToken),
        })
        .then((res) => {
          if (res.data.updated || res.data.created) {
            setSession({
              success: true,
              token: JSON.parse(localSessionToken),
            })

            if (res.data.manifest) {
              setManifest(res.data.manifest)
            }

            if (res.data.remaining) {
              setRemaining(res.data.remaining)
            } else {
              if (res.data.session) {
                if (res.data.session.remaining) {
                  setRemaining(res.data.session.remaining)
                }
              }
            }
          }
          if (res.data.finished) {
            localStorage.removeItem('chaflow_session')
            setSession({ finished: true })
          }
        })
        .catch((err) => {
          console.error(err)
        })

      return
    } else {
      const incoming = {
        objectId: routeParams.uraId,
        chatflowId: routeParams.treeId,
        node: routeParams.nodeId,
      }
      console.log('REQUESTING_NEW_TOKEN_AND_MANIFEST', incoming)

      let fingerprint = await computeFingerprint()

      axios
        .post(`${getDomain('backend')}/player/get-manifest-and-session`, {
          incoming: incoming,
          app: {
            codename: 'chatflow-alpha-ref',
            timecode: new Date().getTime(),
            ua: navigator.userAgent,
            fingerprint: fingerprint,
          },
        })
        .then((res) => {
          if (
            !res.data.manifest ||
            !res.data.session ||
            !res.data.session.success
          ) {
            console.error('Failed to create session 1')

            let retries = totalRetries
            console.log('RESULT_', retries, res.data)

            if (res.data.session.message === 'no-senders-available') {
              retryTimeout = setTimeout(() => {
                setTotalRetries(retries + 1)
                getManifestAndSession()
              }, 5000)
            }
          } else {
            const newToken = res.data.session.token
            console.log('_RESULT_____got token', newToken)

            if (res.data.manifest) {
              console.log('MANIFEST_NEW', res.data.manifest)
              setManifest(res.data.manifest)
            }

            // refresh created token
            axios
              .post(`${getDomain('ghost')}/token`, {
                token: newToken,
              })
              .then((res) => {
                if (res.data.updated || res.data.created) {
                  setSession({
                    success: true,
                    token: newToken,
                  })

                  if (res.data.remaining) {
                    setRemaining(res.data.remaining)
                  } else {
                    if (res.data.session) {
                      if (res.data.session.remaining) {
                        setRemaining(res.data.session.remaining)
                      }
                    }
                  }
                }
                if (res.data.finished) {
                  localStorage.removeItem('chaflow_session')
                  setSession({ finished: true })
                }
              })
              .catch((err) => {
                console.error(err)
              })
          }
        })
        .catch((err) => {
          console.error('Failed to create session 0')
          console.error(err)
        })
    }
  }

  useEffect(() => {
    async function fetchManifestAndSession() {
      return await getManifestAndSession()
    }

    if (session && session.token && !session.finished) {
      localStorage.setItem('chaflow_session', JSON.stringify(session.token))
    }
    if (session && session.finished) {
      localStorage.removeItem('chaflow_session')
      getManifestAndSession()
    }
    if (!session) {
      fetchManifestAndSession()
    }
  }, [session])

  if (!session) {
    return (
      <div className="w-full h-full flex items-center justify-center bg-blue-500 text-white">
        Aguardando sessão.{' '}
        {totalRetries > 0 ? `[Tentativas: ${totalRetries}]` : ''}
      </div>
    )
  }

  if (session.finished) {
    return (
      <div className="w-full h-full flex items-center justify-center bg-blue-500 text-white">
        Sessão finalizada.
      </div>
    )
  }
  return (
    <div className="w-full h-full bg-gray-900 flex flex-col text-white">
      {React.cloneElement(children, {
        session,
        remaining,
        manifest,
        routeParams,
      })}
    </div>
  )
}

export default SessionWrapper

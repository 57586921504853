import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import io from 'socket.io-client'
import { getDomain, generateGUID } from './utils'
import './index.css'
import VideoPlayer from './comps/VideoPlayer'
import SessionWrapper from './comps/SessionWrapper'
import axios from 'axios'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

/**
 * Renderiza o componente PlayerWrapper.
 *
 * @param {Object} props - O objeto de props contendo as seguintes propriedades:
 *   - session: O objeto de sessão.
 *   - sendCommand: A função para enviar um comando.
 *   - isDebugMode: ybool indicando se o modo debug está ativado.
 *   - isConnected: ybool indicando se a conexão está estabelecida.
 *   - deliveryStatus: O status de entrega.
 *   - clipInfo: As informações do clipe.
 * @return {JSX.Element} O componente PlayerWrapper renderizado.
 */
const PlayerWrapper = (props) => {
  const {
    session,
    sendCommand,
    isDebugMode,
    isConnected,
    deliveryStatus,
    clipInfo,
  } = props

  const [isReady, setIsReady] = useState(false)
  const [messageStatus, setMessageStatus] = useState(null)
  const [sessionSync, setSessionSync] = useState(null)

  let msgTimeout = null

  const sendMSG = (msg) => () => {
    console.log('SENDING_', msg)
    clearTimeout(msgTimeout)
    msgTimeout = setTimeout(() => {
      setMessageStatus('waiting')
      sendCommand(
        'to-ghost',
        {
          message: msg,
          envelopId: new Date().getTime(),
        },
        (newStatus) => {
          setMessageStatus(newStatus)
        }
      )
    }, 500)
  }

  useEffect(() => {
    if (isConnected && session && session.token) {
      console.log('NEW_SESSION_CHILD', isConnected, session)

      if (!sessionSync) {
        sendCommand('set_session', { token: session.token }, (newStatus) => {
          console.log('SET_SESSION_CHILD_CALLBACK', newStatus)
        })
        setSessionSync(true)
      }

      setIsReady(true)
    }
  }, [session, sessionSync, isConnected])

  useEffect(() => {
    if (isDebugMode) {
      setMessageStatus(deliveryStatus)
    }
  }, [deliveryStatus, isDebugMode])

  return (
    <div className="w-full h-full bg-black flex flex-col overflow-hidden">
      <div className="app w-full h-full bg-gray-900 flex flex-col text-white">
        <div className="w-full flex flex-col animate-jump-in animate-delay-300 animate-once">
          <h1>
            Chat <span className="font-bold">Flow</span> App
          </h1>
          <div className="w-full flex items-center justify-start">
            <div
              onClick={sendMSG(
                `hello-world from chatflow client tempo-agora: ${new Date()}`
              )}
              className="bg-red-400 rounded p-2 mr-4 cursor-pointer"
            >
              SEND
            </div>
            <div className="text-xs rounded bg-gray-700 p-2">
              {messageStatus}
            </div>
          </div>
        </div>
        <div className="flex-1 w-full relative overflow-hidden">
          {clipInfo && (
            <VideoPlayer
              {...props}
              clipInfo={clipInfo}
              onOptionSelect={(e) => {
                clearTimeout(msgTimeout)
                msgTimeout = setTimeout(() => {
                  sendCommand(
                    'to-ghost',
                    {
                      message: e,
                      envelopId: new Date().getTime(),
                    },
                    (newStatus) => {
                      setMessageStatus(newStatus)
                    }
                  )
                }, 500)
              }}
              className="absolute w-full h-full object-cover"
            />
          )}
          {!clipInfo && (
            <div className="absolute w-full h-full flex items-center justify-center text-white bg-black">
              Aguardando clipe
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

/**
 * Gerencia conexão com socket e comunicação com o componentes Child
 *
 * @param {Object} props - O objeto de propriedades.
 * @param {Object} props.session - O objeto de sessão.
 * @param {Object} props.manifest - O objeto de manifesto.
 * @param {Object} props.routeParams - O objeto de parâmetros de rota.
 * @return {JSX.Element} O componente wrapper renderizado.
 */

const SocketWrapper = (props) => {
  const { session, manifest, routeParams, isDebugMode } = props

  const [isConnected, setConnected] = useState(false)
  const [socket, setSocket] = useState(null)

  const [username, setUsername] = useState(generateGUID())
  const [deliveryStatus, setDeliveryStatus] = useState(null)
  const [clipInfo, setClipInfo] = useState(null)

  let keepAliveInterval = null

  const welcomeUser = (data) => {
    const { message, sender, id } = data
    console.log('welcomeUser', message)
  }
  const updateSocketCount = (data) => {
    const { clientCount } = data
    console.log('updateSocketCount', clientCount)
  }
  const sendMessage = (data) => {
    console.log(data)
  }

  const checkConnection = (timer) => {
    return isConnected && socket
  }

  const keepAlive = () => {
    if (checkConnection(new Date().getTime())) {
      // console.log('keepAlive', `${socket.id}:${username}`)
      socket.emit('/player/keepalive', {
        id: socket.id,
        username: socket.username,
        timecode: new Date().getTime(),
      })
    } else {
      console.log('keepAlive: socket not found')
    }
  }
  /**
   * Envia paylods para Backend Chatflow
   *
   * @param {string} topic - Tópico.
   * @param {object} payload - Payload.
   * @param {function} callback - Callback
   * @return {void}
   */
  const sendCommand = (topic, payload, callback) => {
    const data = payload
    if (isDebugMode) {
      data.isDebugMode = true
    }

    if (checkConnection()) {
      socket.emit(`/player/${topic}`, {
        payload: data,
        id: socket.id,
      })
      callback('sent')
    } else {
      console.error('sendCommand: socket not found', socket)
    }
  }

  // SOCKET PART

  useEffect(() => {
    let host = getDomain('socket')
    let socketConn = io(host)
    socketConn.auth = { username }
    socketConn.connect()
    socketConn.emit('/root/new_socket_connected')

    socketConn.on('/root/welcome', (data) => {
      console.log('welcome', data)
      welcomeUser({
        message: 'Welcome to ChatFlow',
        sender: 'ChatFlow',
        id: 'chatflow',
      })
    })
    socketConn.on('/root/update_socket_count', (data) => {
      console.log('update_socket_count', data)
      updateSocketCount(data)
    })

    socketConn.on('/root/ask_session', (data) => {
      console.log('ask_session', data)
    })

    socketConn.on('/root/update-clip', (data) => {
      console.log('update-clip', data)
      setClipInfo(data)
    })

    socketConn.on('/root/delivery-status', (data) => {
      console.log('delivery-status', data)
      setDeliveryStatus(data?.status || 'error')
    })

    socketConn.on('/root/error', (data) => {
      console.error('error-from-server', data)
    })

    socketConn.on('connect', () => {
      setSocket(socketConn)
      setConnected(true)
      console.log('Connected to chatflow server')
    })

    socketConn.on('disconnect', () => {
      setConnected(false)
      setSocket(null)
      console.log('Disconnected from chatflow server')
    })

    return () => {
      socketConn.disconnect()
    }
  }, [])

  useEffect(() => {
    if (socket && isConnected) {
      clearInterval(keepAliveInterval)
      keepAliveInterval = setInterval(keepAlive, 1000)
    } else {
      clearInterval(keepAliveInterval)
    }

    return () => {
      clearInterval(keepAliveInterval)
    }
  }, [socket, isConnected])

  return (
    <div id="wrapper-socket" className="w-full h-full flex bg-gray-900">
      {React.cloneElement(props.children, {
        sendCommand,
        isConnected,
        session,
        manifest,
        deliveryStatus,
        clipInfo,
      })}
    </div>
  )
}

const App = () => {
  const urlParsed = new URL(window.location.href)
  const routeParams = urlParsed.pathname.split('/').filter((x) => x)
  const urlParams = new URLSearchParams(window.location.search)
  const isDebugMode = urlParams.get('debug') && urlParams.get('debug') === '1'

  return (
    <SessionWrapper
      routeParams={{
        uraId: routeParams[0],
        treeId: routeParams[1],
        nodeId: routeParams[2],
      }}
      isDebugMode={isDebugMode}
    >
      <SocketWrapper isDebugMode={isDebugMode}>
        <PlayerWrapper isDebugMode={isDebugMode} />
      </SocketWrapper>
    </SessionWrapper>
  )
}
root.render(<App />)
